import React, { useState } from 'react'

const Result = ({ value=0, obsVal=0, trueVal=0 }) => {

	return (
		<div className="panel is-primary">
			<p className="panel-heading">
				Percent{value < 0 ? ` error` : ``}
			</p>
			<div className="panel-block">
				<h2><strong>{parseFloat(value*100)}%</strong></h2>
			</div>
			<pre className="panel-block">
				( V<sub>observed</sub> - V<sub>true</sub> ) / V<sub>true</sub>
			</pre>
			{value != 0 ? (
				<>
				<div className="panel-block">
					<p><strong>Step 1</strong></p>
				</div>
				<pre className="panel-block">
					( {obsVal} - {trueVal} ) / {trueVal}
				</pre>
				<div className="panel-block">
					<p><strong>Step 2</strong></p>
				</div>
				<pre className="panel-block">
					{obsVal - trueVal} / {trueVal}
				</pre>
				<div className="panel-block">
					<p><strong>Step 3</strong></p>
				</div>
				<pre className="panel-block">
					{value*100}%
				</pre>
				{value >= 0 ? `` : (
					<>
						<div className="panel-block">
						<p><strong>Step 4</strong></p>
						</div>
						<pre className="panel-block">
							{Math.abs(value)*100}% error
						</pre>
					</>
				)}
				</>
			) : ``}
		</div>
	)
}

const PercentErrorCalculator = () => {

	const [obsVal, setObsVal] = useState(9)
	const [trueVal, setTrueVal] = useState(11)
	const [result, setResult] = useState({})
	
    const calculate = e => {
		e.preventDefault()
		const value = (obsVal - trueVal) / trueVal
		setResult({ value, obsVal, trueVal })
	}
	
    return (
		<form className="form" onSubmit={calculate}>

			<div className="columns">

				<div className="column">

					<div className="field">
						<label className="label" htmlFor="obsVal">
							Observed Value
						</label>
						<div className="control">
							<input
								className="input"
								type="text"
								id="obsVal"
								placeholder="11"
								value={obsVal}
								onChange={e => setObsVal(e.target.value)}
							/>
						</div>
						<p className="help">The measured value.</p>
					</div>

					<div className="field">
						<label className="label" htmlFor="trueVal">
							True Value
						</label>
						<div className="control">
							<input
								className="input"
								type="text"
								id="trueVal"
								placeholder="10"
								value={trueVal}
								onChange={e => setTrueVal(e.target.value)}
							/>
						</div>
						<p className="help">The correct value.</p>
					</div>

					<div>
						<button
							type="submit"
							className="button is-primary"
						>
							Calculate
						</button>
					</div>

				</div>

				<div className="column">
					<Result {...result} />
				</div>

			</div>

		</form>
	)
}

PercentErrorCalculator.propTypes = {}

PercentErrorCalculator.defaultProps = {}

export default PercentErrorCalculator
