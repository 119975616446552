import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PercentErrorCalculator from '../tools/PercentErrorCalculator'

const IndexPage = () => (
    <Layout hideNavigation={true}>
        <SEO title="Home" />

        <section className="hero">
            <div className="hero-body">
                <div className="container">
                    <h1 className="title">Welcome to EpicToolkit.com!</h1>
                    <h2 className="subtitle">Your library of online tools for numbers, date and time, text, maths, financials, accounting, dummy content, and a lot more.</h2>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container content">
                <PercentErrorCalculator />
            </div>
        </section>
    </Layout>
)

export default IndexPage
